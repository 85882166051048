/** vendor */
import React, { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

/** components */
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

export default function IngredientsDropdown({ ingredients, type = 'AISLENINE' }) {
    const [showIngredients, setShowIngredients] = useState(false)

    const formatQuantity = (value) => {
        const stringValue = value.toString()

        if (stringValue.includes('.')) {
          const [integerPart, decimalPart] = stringValue.split('.')
      
          if (/^0+$/.test(decimalPart)) {
            return parseInt(integerPart, 10)
          }
        }
      
        return value    
    }

    return(
        <div className={`px-2 py-3 w-screen relative`}>
            <div className={`${ showIngredients ? 'fixed h-screen w-screen bg-black opacity-50 top-0 left-0 z-40' : 'hidden' }`}></div>
            <div 
                style={{ bottom: 'calc(100% - 12px)' }}
                className={`absolute left-0 w-screen px-2 ${showIngredients ? 'z-50' : 'hidden'}`}>
                <div className={`border rounded py-4 border-black bg-white`}>
                    { showIngredients &&
                        <ul className="max-h-[80vh] overflow-y-auto">
                            {
                                ingredients.map((i) =>
                                {
                                    const ingredientImage = (i?.image) ? `${IMG_URL}/${i.image}` : PLACEHOLDER_IMG

                                    return (
                                        <li className="p-2 border-t min-w-full" key={i?.recipe_ingredient_id || i?.value}>
                                            <div className="flex items-center">                                                     
                                                <div className="flex-1 min-w-0">
                                                    <div className={`text-sm font-light truncate text-black flex w-full justify-between items-center`}>
                                                        <div className="whitespace-normal">{i?.quantity && formatQuantity(i?.quantity)} {i?.measurement} {i?.treatment} {i?.ingredient?.name || i?.name}</div>
                                                        { 
                                                            i?.ingredient?.allergen  
                                                                ? <div className="text-orange-400 opacity-40 text-xs h-6 flex items-center text-center">
                                                                    <ExclamationCircleIcon className="h-6 w-6" />
                                                                </div> : <></> 
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul> 
                    }                    
                </div>
            </div>

            <div className={`w-full p-2 relative rounded flex justify-between border border-black bg-white ${showIngredients ? 'z-50' : ''}`} onClick={ () => { setShowIngredients(!showIngredients) }}>
                <label className="block font-light text-black">Ingredients</label>
                <button>
                    { showIngredients ? <ChevronUpIcon className="h-6 w-6" /> : <ChevronDownIcon className="h-6 w-6" /> }
                </button>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import sidebarItems from '../../constants/SidebarItems'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import pwaInstaller from '../../services/installation.service'
import { forceUpdate } from '../../serviceWorkerRegistration'

import Logout from '../Logout/Logout'

import { 
    XCircleIcon,
    MenuIcon,  
    CakeIcon, 
    ClipboardListIcon, 
    BeakerIcon, 
    AdjustmentsIcon,
    ViewGridIcon, 
    BellIcon,
    FolderOpenIcon,
    FolderIcon,
} from '@heroicons/react/solid'

import {
    XIcon, 
    HomeIcon,     
    CalendarIcon,
    LightBulbIcon,
    SearchIcon,
    BookOpenIcon,
    DatabaseIcon
} from '@heroicons/react/outline'

export default function Sidebar({ 
    onToggleWakeLock,
    onToggleRotationLock 
}) {
    const location = useLocation()
    const user = useSelector(state => state?.auth?.user?.user)
    const [showSidebar, setShowSidebar] = useState(false)
    const [wakeLock, setWakeLock] = useState(false)
    // @todo thisis a reverse flag, refactor this later
    const [rotationLock, setRotationLock] = useState(false)

    const [hasUpdate, setHasUpdate] = useState(false)
    const [installedVersion, setInstalledVersion] = useState('')
    const [availableVersion, setAvailableVersion] = useState('')

    const [showPwaInstallButton, setShowPwaInstallButton] = useState(false)
    const [showIOSinstallInfo, setShowShowIOSinstallInfo] = useState(false)

    let items = []

    // if the service worker automatically detects it should update
    const checkForUpdates = (updateReady) => {
        let tries = 0
        const limit = 5

        const checkUpdateInterval = setInterval(() => { 
            setHasUpdate(updateReady)

            if (updateReady || tries === limit) {
                clearInterval(checkUpdateInterval)
                return
            }

            tries++
        }, 2000)
    }

    // if the developer has explictly updated the version number
    const getLatestAppVersion = async (currentVersion) => {
        const availableVersion = await fetch(window.location.origin + '/latest-version.json', {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
            }
          })
        const version = await availableVersion.json()

        if (currentVersion !== version?.currentVersion) {
            setAvailableVersion(version?.currentVersion)
            setHasUpdate(true)
            return
        }

        checkForUpdates(window['pwaUpdateReady'])
    }
    
    useEffect(() => {
        const currentVersion = localStorage.getItem('installedVersion')

        setInstalledVersion(currentVersion)
        getLatestAppVersion(currentVersion)
    }, [])


    useEffect(() => {
        if ( pwaInstaller.canInstall_iOS() ) {
            setShowShowIOSinstallInfo(true)
        } else {

            pwaInstaller.addListener((canInstall) => {
                setShowPwaInstallButton(canInstall)
            })
        }
    }, [])

    if (sidebarItems) { 
        items = [...sidebarItems] 
        items = sidebarItems.map((r) => {
            const Icon = (props) => {
                const { name } = props

                if (name === 'DatabaseIcon') return  <DatabaseIcon { ...props } />
                if (name === 'BookOpenIcon') return  <BookOpenIcon { ...props } />
                if (name === 'SearchIcon') return  <SearchIcon { ...props } />
                if (name === 'ViewGridIcon') return  <ViewGridIcon { ...props } />
                if (name === 'CakeIcon') return  <CakeIcon { ...props } />
                if (name === 'CalendarIcon') return  <CalendarIcon { ...props } />
                if (name === 'CalendarDaysIcon') return  <CalendarIcon { ...props } />                
                if (name === 'ClipboardListIcon') return  <ClipboardListIcon { ...props } />
                if (name === 'BeakerIcon') return  <BeakerIcon { ...props } />
                if (name === 'AdjustmentsIcon') return  <AdjustmentsIcon { ...props } />
                if (name === 'FolderOpenIcon') return  <FolderOpenIcon { ...props } />
                if (name === 'BellIcon') return  <BellIcon { ...props } />
                if (name === 'HomeModernIcon') return  <HomeIcon { ...props } />
                if (name === 'FolderIcon') return  <FolderIcon { ...props } />
                if (name === 'RetangleGroupIcon') return  <XCircleIcon   { ...props } />  
                if (name === 'LightBulbIcon') return  <LightBulbIcon { ...props } />
                if (name === 'InboxStackIcon') return  <XCircleIcon { ...props } />
                return <></>
            }

            return (
                <li 
                    key={r.route} 
                    className={`p-1 mr-4 ${ r.route.includes(location?.pathname) ? 'text-black bg-white' : ''}`} 
                    style={{ borderBottom: '1px solid rgba(255,255,255, 0.15)' }}
                >
                    <Link to={r.route} onClick={() => setShowSidebar(!showSidebar)} className="flex items-center">
                        <Icon name={r.icon} className="h-8 w-8 pr-2 font-light opacity-80" />
                        <span>{r.label}</span>
                    </Link>
                </li>
            )
        })
    }

    const installApp = (e) => {
        e.preventDefault()
        pwaInstaller.install()
    }

    const installAppiOS = (e) => {
        e.preventDefault()
        alert('todo - make a nice explaination on why ios sucks')
    } 

    const handleUpdate = (e) => {
        e.preventDefault()
        window['pwaUpdateReady'] = true
        forceUpdate(availableVersion)
    }

    const handleOnWakeLock = () => {
        setWakeLock(!wakeLock)
        onToggleWakeLock(!wakeLock)
    }

    return (
        <div className="h-screen">
            {
                !showSidebar &&
                    <button
                        className="flex text-4xl text-white items-center cursor-pointer fixed left-2 top-4 z-40"
                        onClick={() => setShowSidebar(!showSidebar)}
                        >
                        <MenuIcon className="h-8 w-8" />
                    </button>
            }
  
            <div 
                className="
                    fixed top-0 left-0 w-full h-full bg-black opacity-0 
                    transition-opacity duration-800 ease-in-out z-40
                    pointer-events-auto
                    "
                style={{ opacity: showSidebar ? 0.5 : 0, width: showSidebar ? '100%' : '1px' }}
            ></div>
            
            <button
                className={
                    `top-0 right-0 h-16 box-border w-[20vw] bg-black ease-in-out duration-300 delay-200 text-white fixed z-50 ${
                    showSidebar ? "translate-x-0 " : "translate-x-[120vw]"
                }`}
                onClick={() => setShowSidebar(!showSidebar)}
            >
                <XIcon className="h-10 w-10 text-white mx-auto" />
            </button>

            <div 
                className={
                    `top-0 left-0 box-border w-[80vw] sm:max-w-[300px] bg-black ease-in-out duration-300 text-white fixed h-screen z-50 ${
                    showSidebar ? "translate-x-0 " : "-translate-x-full"
                }`}
            >

                <div className="flex flex-col py-2 h-screen">

                    { /** user, wake lock, rotation lock */}
                    <div className="flex-shrink-0 flex justify-between border-b pb-1 mx-4 relative">
                        
                        <Link to="/user-preferences" onClick={() => setShowSidebar(!showSidebar)}>
                            <div className="flex">
                                {
                                    user && 
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                            <div className="pl-2 w-3/5">
                                                <p className="text-white text-base truncate pt-3 font-semibold">{ user?.name }</p>
                                            </div>       
                                        </>
                                }
                            </div>
                        </Link>

                        <div className="absolute right-0 bottom-[-28px]">
                            <div className="flex justify-end items-center">
                                <label className="text-xs lowercase pr-1">Wake lock</label>
                                <div className="relative">
                                    <div className="w-full flex justify-end">

                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input 
                                            type="checkbox" 
                                            value="wakeLock"
                                            onChange={() => {
                                                handleOnWakeLock()
                                            }}
                                            checked={wakeLock} 
                                            className="sr-only peer" 
                                            />
                                            <div 
                                            
                                            className={`
                                                w-9 h-5 bg-red-800
                                                rounded-full peer
                                                peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                                                after:absolute after:top-[2px] after:left-[2px] 
                                                after:bg-white
                                                    after:rounded-full after:h-4 after:w-4 after:transition-all 
                                                peer-checked:bg-green-800

                                            `}>&nbsp;</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    { /** main menu */}
                    <ul className="overflow-y-auto flex-shrink flex-grow pl-4 pb-4 pt-12">
                        {items}

                        <li className="border-b-gray-500 py-2">
                            <Logout />
                        </li>
                    </ul>

                    { /** footer */}
                    <div className="flex-shrink-0 pb-2 px-2 border-t relative">
                        <div className="absolute top-[-24px] right-1">  
                            <div className="w-full flex justify-between pb-1">
                                {
                                    hasUpdate &&
                                        <button 
                                            onClick={(e) => handleUpdate(e) } 
                                            className="rounded border px-2 text-xs font-medium lowercase text-center bg-white text-black"   
                                        >
                                            <span>Update</span>
                                        </button>
                                }

                                {
                                    showPwaInstallButton && 
                                        <button 
                                            type="button"
                                            onClick={(e) => { installApp(e) }}
                                            className="rounded border px-2 text-xs font-medium lowercase text-center bg-white text-black"   
                                        >Install</button>
                                }

                                {
                                    showIOSinstallInfo &&
                                        <button 
                                            type="button"
                                            onClick={(e) => { installAppiOS(e) }}
                                            className="rounded border px-2 text-xs font-medium lowercase text-center bg-white text-black"   
                                        >Install</button>
                                }
                            </div>   
                        </div>

                        <div className="flex items-center justify-end mt-1 z-50 ">
                            <small className="text-white text-xs block mr-auto mt-1 opacity-60">Version: {installedVersion}</small>        

                            <Link to="/about" onClick={() => setShowSidebar(!showSidebar)}>
                                <span className="text-xs opacity-60 text-white cursor-pointer underline">About</span>
                            </Link>
                            <span className="text-xs opacity-60 text-white px-2 mt-1"> | </span>
                            <Link to="/legal" onClick={() => setShowSidebar(!showSidebar)}>
                                <span className="text-xs opacity-60 text-white cursor-pointer underline">Legal</span>
                            </Link>
                            <span className="text-xs opacity-60 text-white px-2 mt-1"> | </span>

                            <Link to="/contact" onClick={() => setShowSidebar(!showSidebar)}>
                                <span className="text-xs opacity-60 text-white cursor-pointer underline">Contact</span>
                            </Link>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}
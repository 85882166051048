/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { EyeIcon, ShoppingBagIcon } from '@heroicons/react/solid'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'
import { useConfirm } from '../../hooks/useConfirm'
import { useToast } from '../../hooks/useToast'
import { removeSelfFromSharedList } from '../../services/share.service'

/** state */
import { deleteShoppinglist, getShoppinglist, getSharedWithMe } from '../../actions/user.actions'

/** components */
import ShoppinglistOptions from '../../components/Shoppinglist/ShoppinglistOptions'
import CompleteShoppingListDialog from '../../components/Dialogs/CompleteShoppingListDialog'
import ShareDialog from '../../components/Dialogs/ShareDialog'
import ReportPriceDialog from '../../components/Dialogs/ReportPriceDialog'

export default function ShoppingListDetail() {
    // vendor utils
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    // a9 utils
    const confirm = useConfirm()
    const toast = useToast()

    // ui states
    const [loading, setLoading] = useState(false)
    const [viewMode, setViewMode] = useState('readonly')
    const [wakeLock, setWakeLock] = useState(false)
    
    // show / hide dialogs
    const [showOptions, setShowOptions] = useState(false)
    const [showCompleteDialog, setShowCompleteDialog] = useState(false)
    const [showShare, setShowShare] = useState(false)
    const [showPriceReportDialog, setShowPriceReportDialog] = useState(false)

    // current list
    const shoppinglist = useSelector(state => state.current_shoppinglist)
    const [groupedShoppinglistState, setGroupedShoppinglistState] = useState([])
    const [shoppinglistState, setShoppinglistState] = useState([])

    // shared with me store permissions
    const [isSharedList, setIsSharedList] = useState(false)
    const [canEditList, setCanEditList] = useState(true)
    const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)
    const currentUsersId = useSelector(state => state.auth?.user?.user?.id)

    // form options
    const ingredientOptions = useSelector(state => state?.form_options?.ingredient_options)
    const categoryOptions = useSelector(state => state?.form_options?.category_options)

    /**
     * Utils
     */
    const setAllowedActions = (shoppinglist) => {
        if(currentUsersId === parseInt(shoppinglist?.user_id)) {
            setIsSharedList(false)
            setCanEditList(true)
            return
        }

        setIsSharedList(true)
    
        let canEdit = false
    
        for(const user of shoppinglist?.shared_with) {
            if(user?.email === currentUsersEmail && user?.canEdit) {
                canEdit = true
                break
            }
        }
    
        setCanEditList(canEdit)
    } 
    /**
     * Hooks
     */
    useEffect(() => {
        setLoading(true)

        dispatch(getShoppinglist(location.pathname.split('/')[2])).then(() => {
            const urlParams = new URLSearchParams(window.location.search)
            const viewModeValue = urlParams.get('viewMode')
            
            if (viewModeValue) {
                setViewMode(viewModeValue)
            }

            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (shoppinglist?.shopping_list) {
            setAllowedActions(shoppinglist?.shopping_list)
        }

        if(shoppinglist?.shopping_list?.ingredients) {
            const listCategoryGroup = [];

            // Generate Ingredient Categories
            shoppinglist?.shopping_list?.ingredients.forEach((ingredient) => {
                const groupItem = { category_name: '', category_id: null, ingredients: [] }

                if (ingredient?.id?.includes('CUSTOM')) {
                    // it's a user created ingredient
                    groupItem.category_id = 'CUSTOM'
                    groupItem.category_name = 'Custom'
                } else {
                    const ingredientOption = ingredientOptions?.find(i => i.value === ingredient?.value)
                    
                    if (ingredientOption?.category_id) {
                        // it's a9 ingredient with a category id
                        const categoryOption = categoryOptions?.find(c => c.value === ingredientOption?.category_id)
                        groupItem.category_id = categoryOption?.value
                        groupItem.category_name = categoryOption?.name
                    } else {
                        // it's a9 ingredient with no category id
                        groupItem.category_id = 'MISC'
                        groupItem.category_name = 'Misc'
                    }
                }

                if (!listCategoryGroup?.find(i => i.category_id === groupItem.category_id))
                {
                    listCategoryGroup.push(groupItem)
                }
            })

            shoppinglist?.shopping_list?.ingredients.forEach((ingredient) => {
                
                const ingredientOption = ingredientOptions?.find(i => i.value === ingredient?.value)
                const categoryOption = categoryOptions?.find(c => c.value === ingredientOption?.category_id)
                const listCategoryGroupItemIndex = listCategoryGroup.findIndex(i => i['category_id'] === categoryOption?.value);

                
                const ii = ingredient
                ii.checked = false
                
                if (listCategoryGroupItemIndex > -1) {
                    listCategoryGroup[listCategoryGroupItemIndex].ingredients.push(ii)
                } else {
                    if (ingredient?.id?.includes('CUSTOM')) {
                        const customGroupItemIndex = listCategoryGroup.findIndex(i => i['category_id'] === 'CUSTOM');
                        listCategoryGroup[customGroupItemIndex].ingredients.push(ii)
                    }
                    else if (!ingredient?.category_id) {
                        const miscGroupItemIndex = listCategoryGroup.findIndex(i => i['category_id'] === 'MISC');
                        listCategoryGroup[miscGroupItemIndex].ingredients.push(ii)
                    }
                }
            })

            setGroupedShoppinglistState(listCategoryGroup)

            const savedState = localStorage.getItem(`shopping-list-local-state-${shoppinglist?.shopping_list?.name ?? 'TITLE-MISSING'}`)
            let checkedItems = []

            if(savedState) {
                const parsedState = JSON.parse(savedState)

                const cachedStateAndListStateAreTheSame = JSON.stringify(
                    JSON.parse(savedState)?.map((item) => {
                        const itemCopy = {...item}
                        delete itemCopy.checked
                        return itemCopy
                    }) === JSON.stringify([...shoppinglist?.shopping_list?.ingredients])
                )
                
                if(cachedStateAndListStateAreTheSame) {
                    checkedItems = parsedState?.filter(i => i?.checked === true).map(i => i?.value)
                } else if(savedState) {
                    localStorage.removeItem(`shopping-list-local-state-${shoppinglist?.shopping_list?.name ?? 'TITLE-MISSING'}`)
                }
            } 

            const shoppinglistState = [...shoppinglist?.shopping_list?.ingredients].map(i => {
                i['checked'] = checkedItems.includes(i?.value)
                return i
            })

            setShoppinglistState(shoppinglistState)
        }
    }, [shoppinglist])

    /**
     * Handlers
     */
    const handleOnConfirmPriceReport = () => {

    }

    const handleOnselectUnit = () => {

    }

    const handleOnShareUpdate = (share_with) => {
        // @todo use new share controller
    }

    const handleShowOptions = () => {
        setShowOptions(true)
    }

    const handleOnIngredientChange = (e, categoryId, ingredient) => {
        const shoppinglistStateCopy = [...shoppinglistState]
        const ingredientIndex = shoppinglistStateCopy?.findIndex(i => i.value === ingredient?.value)
        
        if (ingredient?.id?.includes('CUSTOM')) {
            console.warn(shoppinglistStateCopy, ingredient)
        } else if (ingredientIndex > -1) {
            shoppinglistStateCopy[ingredientIndex].checked = e.target.checked
        }     

        localStorage.setItem(`shopping-list-local-state-${shoppinglist?.shopping_list?.name ?? 'TITLE-MISSING'}`, JSON.stringify(shoppinglistStateCopy))
        setShoppinglistState(shoppinglistStateCopy)
    }

    const handleOnConfirmCompleteList = async () => {
        await dispatch(getShoppinglist(location.pathname.split('/')[2]))
        localStorage.removeItem(`shopping-list-local-state-${shoppinglist?.shopping_list?.name ?? 'TITLE-MISSING'}`)
        setShowCompleteDialog(false)
    }

    const handleOnCancelCompleteList = () => {
        setShowCompleteDialog(false)
    }

    const handleToggleShopMode = () => {
        const mode = viewMode === 'readonly' ? 'shopmode' : 'readonly'
        
        // updateParams(mode)
        setViewMode(mode)
    }

    const handleOnAction = (action, id) => {

        if (action === 'delete') {
            confirm.open(
                `Delete ${shoppinglist?.shopping_list?.name}?`,
                'This action cannot be undone.',
                () => {
                    setLoading(true)

                    dispatch(deleteShoppinglist(id)).then(() => {
                        setLoading(false)
                        toast.open('List Deleted.')
                        navigate(`/user-shopping-lists`)  
                    }).catch(() => {
                        setLoading(false)
                    })
                }
            )
        }
    
        if (action === 'shop') {
            setShowOptions(false)
            setViewMode('shopmode')
        }   

        if (action === 'share') {
          setShowOptions(false)
          setShowShare(true)
        }

        if (action === 'edit') {
            navigate(`/user-shopping-lists/edit/${id}`)  
        }

        if (action === 'remove') {
            confirm.open(
              `Remove ${shoppinglist?.title} from shared lists?`,
              'This action cannot be undone.',
              () => {
                setLoading(true)
      
                removeSelfFromSharedList({entity_id: shoppinglist?.shopping_list?.user_shoppinglist_id, entity_type: 'UserShoppinglist'}).then(() => {
                  dispatch(getSharedWithMe()).then(() => {
                    setLoading(false)
                    setShowOptions(false)

                    navigate(`/user-shopping-lists`)

                    setTimeout(() => {
                        toast.open('List Removed.')
                    }, 200)
                  })
                }).catch(() => {
                  setLoading(false)
                })
      
              })
          }

        if (action === 'clipboard') {
            const ingredients = [...shoppinglist?.shopping_list?.ingredients].map(i => {
                return `${i?.name}`
            })
            
            const text = ingredients.join('\r\n')

            if ('clipboard' in navigator) {
                navigator.clipboard.writeText(text)
                toast.open(`Copied to Clipboard`)
                return
            } 

            const element = document.createElement("textarea")

            element.value = text
            document.body.appendChild(element)
            element.select();
            document.execCommand("copy")
            document.body.removeChild(element)
            
            toast.open(`Copied to Clipboard`)
        } 

        if (action === 'report') {
            setShowOptions(false)
            setShowPriceReportDialog(true)
        }
    }
    
    return (
        <div className="pt-16 min-w-full">
            <Card
                autoHeight={true} 
                title={shoppinglist?.shopping_list?.name || ''}
                status={
                    <div className="flex">
                        {
                            viewMode === 'readonly' ? 
                                <button
                                    className="text-xs h-6 w-6 text-black rounded-full mr-2"
                                    onClick={ (e) => { handleToggleShopMode() } }
                                >
                                    <ShoppingBagIcon />
                                </button>
                            : ''
                        }

                        {
                            viewMode === 'shopmode' ? 
                            <button
                                className="text-xs h-6 w-6 text-black rounded-full mr-2"
                                onClick={ (e) => { handleToggleShopMode() } }
                            >
                                <EyeIcon />
                            </button>
                            : ''
                        }

                        <button
                            className="text-xs h-6 w-6 text-black rounded-full"
                            onClick={ (e) => { handleShowOptions() } }
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                            </svg>
                        </button>

                    </div>
                }
            >
                <div className="overflow-y-auto" style={{height:  `${viewMode === 'shopmode' && canEditList ? 'calc(100vh - 200px)': 'calc(100vh - 144px)'}`}}>
                    { 
                        loading && <div className="h-[60vh] relative pt-40"><Loading /></div> 
                    }
                    { 
                        !loading &&
                            <div className="min-w-full flex justify-center">
                                <ul role="list" className="divide-y divide-black min-w-full">
                                    {
                                        groupedShoppinglistState &&
                                            groupedShoppinglistState.map((category, categoryIndex) => { 
                                                if (category?.ingredients?.length < 1) {
                                                    return <React.Fragment key={categoryIndex} />
                                                }

                                                return (
                                                    <li className="pb-3 sm:pb-4 min-w-full" key={categoryIndex}>
                                                        <h2 className="text-xl border-b bg-gray-50 border-black py-2">{category?.category_name}</h2>
                                                        <ul role="list" className="divide-y divide-gray-200 min-w-full">
                                                            {
                                                                category?.ingredients.map((ingredient, ingredientIndex) => {                                                             
                                                                    return (
                                                                    <li className="py-3 sm:py-4 min-w-full" key={ingredientIndex}>
                                                                        <div className="flex items-center space-x-4">
                                                                            <div className="flex-shrink-0 pl-2">
                                                                                <input 
                                                                                    className={`${viewMode !== 'shopmode' && 'opacity-50'} border w-12 mr-1 border-gray-400 text-black text-center text-xs rounded-t p-[4px] appearance-none`} 
                                                                                    id="quantity" 
                                                                                    type="number"
                                                                                    min="1"
                                                                                    disabled={viewMode !== 'shopmode'}
                                                                                    onChange={(e) => {}}
                                                                                    value={ingredient?.quantity || ingredient?.default_quantity} 
                                                                                />
                                                                                <div className="w-12 mb-1">
                                                                                    <div className="w-full overflow-hidden">
                                                                                        <select 
                                                                                            id="measurement"
                                                                                            disabled
                                                                                            value={ingredient?.measurement || ingredient?.default_unit || 'unit'}  
                                                                                            style={{ fontSize: 12 }}
                                                                                            className="border bg-black border-black opacity-50 w-full text-center text-white leading-none rounded-b block px-1 py-1"
                                                                                        > 
                                                                                            <option value={ingredient?.measurement || ingredient?.default_unit || 'unit'}>{ingredient?.measurement || ingredient?.default_unit ||  'unit'}</option>
                                                                                        </select>
                                                                                    </div>                                                                        
                                                                                </div>
                                                                            </div>

                                                                            <div className="flex-1 min-w-0">
                                                                                <p className="text-md font-medium text-black">
                                                                                    {ingredient.name}
                                                                                </p>
                                                                            </div>
                                                                            <div className="inline-flex items-center text-base font-semibold text-black pr-2">
                                                                                {
                                                                                    viewMode === 'shopmode' && !ingredient?.id?.includes('CUSTOM') ?
                                                                                        <input 
                                                                                            onChange={(e) => { handleOnIngredientChange(e, category?.category_id, ingredient) }}
                                                                                            checked={ingredient?.checked} 
                                                                                            type="checkbox" 
                                                                                            value={ingredient?.id}
                                                                                            className="w-4 h-4 mr-2 text-black bg-white border-black rounded" 
                                                                                        /> : <></> 
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )}) 
                                                            }
                                                        </ul>
                                                    </li>
                                                )
                                            })
                                    }
                                    {
                                        groupedShoppinglistState?.length < 1 &&
                                            <ul className="w-full">
                                                <li className="text-center pt-40">
                                                    <p className="text-center py-4 text-gray-400">No Items Found</p>
                                                </li>
                                            </ul>    
                                    }
                                </ul>                
                            </div>
                    }
                </div>
            </Card>
            
            <ShoppinglistOptions 
                listId={shoppinglist?.shopping_list?.user_shoppinglist_id} 
                title={shoppinglist?.shopping_list?.name} 
                showDialog={showOptions}
                onAction={handleOnAction}
                canEdit={canEditList}
                isSharedWithUser={isSharedList} 
                onCancel={ () => { setShowOptions(false) } } 
            />

            {
                viewMode === 'shopmode' && !showCompleteDialog && canEditList ? 
                    <div className="fixed bottom-0 left-0 w-screen py-4 flex justify-center">
                        <button 
                            onClick={()=> { setShowCompleteDialog(true) }}
                            type="button" 
                            className={
                                `rounded font-medium border border-black text-sm px-5 py-2
                                ${showCompleteDialog ? 'text-black bg-white opacity-30' : 'text-white bg-black'}
                                `}
                        >Store Items</button>
                    </div> : <></>
            }

            <CompleteShoppingListDialog
                onConfirm={handleOnConfirmCompleteList} 
                onCancel={handleOnCancelCompleteList}
                showDialog={showCompleteDialog}
                shoppinglistState={shoppinglistState}
                shoppinglistId={shoppinglist?.shopping_list?.user_shoppinglist_id} 
            />

            <ShareDialog
                entityId={shoppinglist?.shopping_list?.user_shoppinglist_id}
                entityType="UserShoppinglist"
                showDialog={showShare}
                title={`Share ${shoppinglist?.shopping_list?.name}`}
                shareWith={shoppinglist?.shopping_list?.shared_with}
                onUpdate={handleOnShareUpdate}
                onCancel={ () => { setShowShare(false) } }
            />

            {/* <ReportPriceDialog 
                ingredients={shoppinglist?.shopping_list?.ingredients} 
                onConfirm={handleOnConfirmPriceReport}
                onCancel={() => { setShowPriceReportDialog(false) }}
                showDialog={showPriceReportDialog} 
            /> */}
        </div>
    )
}
